import React, { useEffect, useRef, useState } from 'react';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Modal, FloatingLabelProps } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import defaultAvathar from '../../assets/images/default-avatar.jpg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAccount } from 'wagmi';
import { post, kycAudit } from '../../utils/api';
import { get, getCUstomers, isexistingreferralcode } from '../../utils/apiGet';
import { Spinner } from 'react-bootstrap';
import apiUploadPost from '../../utils/apiUploadPost';
import { store } from '../../store';
import { setUserID } from '../../reducer/rootReducer';
import InputGroup from 'react-bootstrap/InputGroup';
import phoneCOde from '../../utils/phoneCode.json';
import jsonCountryCode from '../../utils/countryCode.json';
// import Moment from '../../components/shared/moment';
import Moment from 'react-moment';
import { validateContentRule, validateContent, emailValidation, containsHtmlTags } from '../../utils/customeValidation';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import loadimg from '../../assets/images/Minnapad-Logo.svg'
import nodata from '../../assets/images/no-data.png';
import Referrals from './referrals';
import Buy from './buy';
import error from '../../assets/images/error.svg'
import Bonus from './bonus';
const ProfileView = (props) => {
  const [form, setForm] = useState({});
  const { isConnected, address } = useAccount();
  const [messageShow, setMessageShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState({
    id: '',
    userName: '',
    firstName: '',
    nickName: '',
    lastName: '',
    email: '',
    phoneNo: '',
    country: '',
    countryCode: '',
    profilePicUrl: '',
    walletAddress: address,
    status: 'Active',
    imageUrl: '',
    kycStatus: '',
    discordId: null,
    referralCode: ''
  });
  const router = useNavigate();
  const [errorMsg, setErrorMsg] = useState(false);
  const [scuess, setSucess] = useState(false);
  const [success, setSuccess] = useState(null);
  const [validated, setValidated] = useState(false);
  const [imageValidtion, setImageValidation] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [userDetailsNestedList, setUserDetailsNestedList] = useState([]);
  const [customerDetails, setCustomerDetails] = useState({});
  const [errors, setErrors] = useState({});
  const [copied, setCpoied] = useState(false);
  const [refeeralCopied, setReferralCpoied] = useState(false);
  const [picLoader, setPicLoader] = useState(false);
  const params = useParams()
  const [touched, setTouched] = useState(false);
  const [referralloader, setReferralLoader] = useState(false)

  const handleRedirect = () => {
    setSuccess(null);
    setReferralLoader(false)
    setSuccess(null);
    setMessageShow(true);
    setErrorMsg(false)
    if (profile.referralCode != null) {
      getIsExistingReferralCOde()
    }
  };
  const shouldLog = useRef(true);
  const box = useRef(null);

  useEffect(() => {
    if (isConnected == false) {
      router('/minnapad/dashboard');
    } else {
      if (shouldLog.current) {
        shouldLog.current = false;
        getCustomerDetail();
      }
      if (params.show) {
        setMessageShow(true);
      }
    }
  }, []); //eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (isConnected && address) {
      getCustomerDetail();
    }

  }, [address]);

  const setField = (field, value) => {
    if (field == "referralCode" && value.length < 6) {
      setErrorMsg(null)
      setTouched(null)
    }
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field]) {
      setErrors({
        ...errors,
        [field]: null,
      });
    }
  };

  const getCustomerDetail = async (picLoader: any) => {
    if (picLoader == true || picLoader == false) {
      setPicLoader(true)
    } else {
      setIsLoading(true);
    }

    let response = await getCUstomers(`User/CustomerDetails/${address}`);
    if (response) {
      if (!response.data.isReferralPage && response.data.kycStatus?.toLowerCase() == 'completed') {
        store.dispatch(setUserID(response.data));
        router(`/minnapad/referrals`)
      }
      setProfile(response.data);
      setForm(response.data);
      saveAuditLogs(response.data.id);
      setCustomerDetails(response.data);
      store.dispatch(setUserID(response.data));

      setIsLoading(false);
      setPicLoader(false)
      if (response.data.kycStatus != null) {
        getCustomerKycDetail(response.data.id);
      }
    } else {
      setErrorMsg(isErrorDispaly(response));
      setIsLoading(false);
      setPicLoader(false)
    }
  };
  const saveAuditLogs = async (id) => {
    let obj = {
      customerId: id,
      feature: 'Sign in',
      remarks: 'string',
      info: JSON.stringify(props.trackAuditLogData),
      createdDate: new Date(),
    };
    let response = await kycAudit(`User/SaveUserAuditLogs`, obj);
  };
  const getCustomerKycDetail = async (id: any) => {
    let response = await getCUstomers(`Sumsub/GetKYCInformation/${id}`);
    if (response) {
      setUserDetails(response.data);
      let products = response.data.idTypes;
      const result = products?.reduce((acc, obj) => {
        const key = obj.idType;
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
      const tableData: any = result ? Object?.entries(result) : [];
      setUserDetailsNestedList(tableData || []);
    } else {
      setErrorMsg(isErrorDispaly(response));
    }
  };


  const getIsExistingReferralCOde = async () => {
    setErrorMsg(null);
    setTouched(null)
    setReferralLoader(true)
    const whiteSpace = /\s/
    const emojiRejex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\u2010-\u2017])/g;
    if (form?.referralCode != null && (!emojiRejex.test(form?.referralCode) && (!whiteSpace.test(form?.referralCode)) && (form?.referralCode?.length > 0))) {
      await isexistingreferralcode(`User/isexistingreferralcode/${form?.referralCode}`)
        .then((response) => {
          if (response) {
            setTouched(response?.data)
            setErrorMsg(null);
            setReferralLoader(false)
          }
        })
        .catch((errors) => {
          setErrorMsg(isErrorDispaly(errors));
          setTouched(null)
          setReferralLoader(false)

        });
    } else if (!form?.referralCode) {
      setErrorMsg("Please provide referral code.");
      setTouched(null)
      setReferralLoader(false)
    } else {
      setErrorMsg("Invalid referral code");
    }
    setReferralLoader(false)
  }

  const validateForm = (obj: any, isChange: any) => {
    const { nickName, firstName, lastName, phoneNo, email, countryCode, country, discordId } = isChange ? obj : form;
    const newErrors = {};
    const reg = /<(.|\n)*?>/g;
    const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
    const whiteSpace = /\s/;
    const emojiRejex =
      /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff]|[\u2010-\u2017])/g;

    if (containsHtmlTags(nickName)) {
      newErrors.nickName = 'Invalid Nickname';
    }
    if (!firstName || firstName === '') {
      newErrors.firstName = 'Is required';
    } else if (validateContentRule('', firstName)) {
      newErrors.firstName = 'Invalid first name';
    }

    if (!lastName || lastName === '') {
      newErrors.lastName = 'Is required';
    } else if (validateContentRule('', lastName)) {
      newErrors.lastName = 'Invalid last name';
    }

    if (!phoneNo || phoneNo === '') {
      newErrors.phoneNo = 'Is required';
    } else if (phoneNo && (reg.test(phoneNo) || phoneNo.match(emojiRejex))) {
      newErrors.phoneNo = 'Invalid phone number';
    }

    if (!email || email == '') {
      newErrors.email = 'Is required';
    } else if (emailValidation('', email)) {
      newErrors.email = 'Invalid email';
    } else if (emailReg == false) {
      newErrors.email = 'Invalid email';
    }

    if (!countryCode || countryCode == 'select') {
      newErrors.countryCode = 'Is required';
    }
    if (!country || country == 'Select Country') {
      newErrors.country = 'Is required';
    }
    if (!discordId || discordId === '') {
      newErrors.discordId = 'Is required';
    } else if (validateContentRule('', discordId)) {
      newErrors.discordId = 'Invalid discord Id';
    }
    return newErrors;
  };

  const saveDetails = async (event: any) => {
    let isUpdate = false
    event?.preventDefault();
    setLoader(true);
    setSuccess(null);
    if (!form.referralCode) {
      isUpdate = true;
    } else if (form.referralCode) {
      if (touched?.isReferral) {
        isUpdate = true;
      } else {
        setErrorMsg("Please verify the referral code");
        setLoader(false);
      }
    }
    if (isUpdate) {
      let obj = {
        id: profile.id ? profile.id : '00000000-0000-0000-0000-000000000000',
        userName: form.userName,
        firstName: form.firstName,
        lastName: form.lastName,
        nickName: form.nickName,
        email: form.email,
        phoneNo: form.phoneNo,
        country: form.country,
        countryCode: form.countryCode,
        profilePicUrl: profile.profilePicUrl,
        walletAddress: address,
        discordId: form.discordId,
        referralCode: form?.referralCode?.length > 0 ? form?.referralCode : null
      };
      const formErrors = validateForm();
      if (Object.keys(formErrors).length > 0) {
        setErrors(formErrors);
        setLoader(false);
      } else {
        let res = await kycAudit(`User/SaveUser`, obj)
          .then((res) => {
            if (res) {
              setValidated(false);
              setSuccess('Profile details saved successfully');
              setSucess(true);
              getCustomerDetail();
              setLoader(false);
              setMessageShow(false);
              scrollToBottom();
              setTimeout(function () {
                setSucess(false);
              }, 2000);
            }
          })
          .catch((error) => {
            if (error.response?.data?.title.includes("referral code")) {
              setErrorMsg(isErrorDispaly(error))
              setLoader(false);
            } else {
              setImageValidation(isErrorDispaly(error));
              setLoader(false);
            }
          });
        setLoader(false);
        setValidated(true);
      }
    }
  };
  // const isErrorDispaly = (objValue: any) => {
  //   if (objValue.data && typeof objValue.data === 'string') {
  //     return objValue.data;
  //   } else if (objValue.originalError && typeof objValue.originalError.message === 'string') {
  //     return objValue.originalError.message;
  //   } else {
  //     return 'Something went wrong please try again!';
  //   }
  // };


  const isErrorDispaly = (objValue) => {
    if ((objValue.status > 400 && objValue.status < 500) && objValue.status != 401) {
      return "Something went wrong please try again!";
    } else {
      if (objValue.data && typeof objValue.data === "string") {
        return objValue.data;
      } else if (objValue.response.data && objValue.response.data.title && typeof objValue.response.data.title) {
        return objValue.response.data.title;
      } else if (
        objValue.originalError &&
        typeof objValue.originalError.message === "string"
      ) {
        return objValue.originalError.message;
      } else {
        return typeof (objValue) === "object" && objValue.reason ? objValue.reason : "Something went wrong please try again!";
      }
    }
  };

  const handleUpload = (event: any) => {
    setImageValidation('');
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (!file.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        setImageValidation('File is not allowed. You can upload jpg, png, jpeg files.');
      } else {
        uploadToServer(file);
      }
    }
  };

  const uploadToServer = async (file: any) => {
    setPicLoader(true);
    const body: any = new FormData();
    body.append('file', file);
    kycAudit(`Upload/UploadFileNew/${customerDetails.id}`, body)
      .then((res) => res)
      .then((data) => {
        let _obj = { ...profile };
        _obj.profilePicUrl = data[0];
        setProfile(_obj);
        setImageValidation(null);
        setSuccess('Profile updated successfully.');
        setSucess(true);
        setTimeout(function () {
          setSucess(false);
          getCustomerDetail(picLoader)
        }, 2000);
        router('/minnapad/profile');
      })
      .catch((error) => {
        setImageValidation(error.response?.data.title);
        setPicLoader(false);
      });

  };
  const handleKyc = () => {
    router('/minnapad/kyc');
  };

  const handleClose = () => {
    setTouched(null)
    setSucess(false);
    setValidated(false);
    setMessageShow(false);
    setErrors({});
    setForm(profile);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCopy = () => {
    setCpoied(true);
    setReferralCpoied(false)
    setTimeout(() => setCpoied(false), 1000);
  };
  const handleReferralCopy = () => {
    setReferralCpoied(true)
    setCpoied(false);
    setTimeout(() => setReferralCpoied(false), 1000);
  };
  if (!isConnected) {
    router("/minnapad/home");
  }
  return (
    <div className="container">
      <div className='card-mt-pt'>
        <Form>
          {imageValidtion && (
            // <Alert variant="danger" className="mt-4">
            //   <p style={{ color: 'red', margin: 10 }} className="d-flex align-items-start error-align">
            //     <span className="icon error-alert me-2 alert-error mt-0"></span>
            //     <span>{imageValidtion}</span>
            //   </p>
            // </Alert>
            <div className='cust-error-bg'>
              <div className='cust-crd-mr'>
                <img src={error}></img>
              </div>
              <div>
                <p className='error-title error-red'>Error</p>
                <p className='mb-0'>{imageValidtion}</p>
              </div>
            </div>
          )}
          {isLoading ? (<div className='loading-overlay'><div className="text-center image-container">
            {/* <Spinner className="text-center" /> */}
            <Image
              className=""
              src={loadimg}
              alt=""
            />
          </div></div>) : (
            <Row className="profile-panel justify-content-center">
              <><Row className='p-0'>
                <Col xl={3} sm={12} className="px-0">
                  <div className="profile-size c-pointer upload-profile-mobile">
                    <Form.Group>
                      <span className="image-box">
                        {' '}
                        <Image
                          className="image-setup"
                          src={profile?.profilePicUrl || profile?.imageUrl || defaultAvathar}
                          width="100"
                          height="100"
                          alt=""
                        />{' '}
                      </span>
                      <span>
                        {picLoader && <Spinner size='sm' className='pic-loader text-white' />} <input type="file" name="myImage" className="icon camera cam-position" onChange={handleUpload} />
                      </span>
                    </Form.Group>
                  </div>
                </Col>

                <Col xl={9} sm={12} className="editPf">
                  <div className="profile-edit-btn text-end pe-lg-0">
                    <Button onClick={handleRedirect} className="c-pointer mt-3 mt-xl-0">
                      <span className="icon edit me-1 c-pointer"></span>Edit Profile
                    </Button>
                  </div>
                  <Row className="px-lg-4 px-2">
                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">Nickname</label>
                      <p className="profile-value">{profile.nickName ? profile.nickName : '-'}</p>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">First Name</label>
                      <p className="profile-value">{profile.firstName ? profile.firstName : '-'}</p>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">Last Name</label>
                      <p className="profile-value">{profile.lastName ? profile.lastName : '-'}</p>
                    </Col>
                    <Col xl={4} sm={12} md={6} className='pe-0'>
                      <label className="profile-label">Email</label>
                      <p className="profile-value word-break">{profile.email ? profile.email : '-'}</p>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">Phone No</label>
                      <p className="profile-value">
                        {profile.phoneNo ? (
                          <>
                            {profile?.countryCode} {profile?.phoneNo}
                          </>
                        ) : (
                          '-'
                        )}
                      </p>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">Country</label>
                      <p className="profile-value">{profile.country ? profile.country : '-'}</p>
                    </Col>
                    {/* {profile.kycStatus != null && (
                      <Col xl={4} sm={12} md={6}>
                        <label className="profile-label">KYC Status</label>
                        {profile.kycStatus.toLowerCase() === "completed" &&
                          <p className={`kyc-badge mt-1 ${profile.kycStatus.toLowerCase() === "completed" && "txt-green"}`}>
                            {profile.kycStatus ? profile.kycStatus : '-'}</p>}
                        {profile.kycStatus.toLowerCase() === "pending" &&
                          <p className={`kyc-badge mt-1 ${profile.kycStatus.toLowerCase() === "pending" && "kyc-pending"}`}>
                            {profile.kycStatus ? profile.kycStatus : '-'}</p>}
                        {profile.kycStatus.toLowerCase() === "init" &&
                          <p className={`kyc-badge mt-1 ${profile.kycStatus.toLowerCase() === "init" && "kyc-pending"}`}>
                            {profile.kycStatus ? profile.kycStatus : '-'}</p>}
                        {profile.kycStatus.toLowerCase() === "rejected" &&
                          <p className={`kyc-badge mt-1 ${profile.kycStatus.toLowerCase() === "rejected" && "kyc-reject"}`}>
                            {profile.kycStatus ? profile.kycStatus : '-'}</p>}
                      </Col>
                    )} */}
                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">Discord ID</label>
                      <p className="cust-profile-value">{profile.discordId ? profile.discordId : '-'}</p>
                    </Col>
                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">Referrer Code</label>
                      <p className="profile-value">{profile?.referralCode || '--'}
                      </p>
                    </Col>

                    <Col xl={4} sm={12} md={6}>
                      <label className="profile-label">My Referral Code</label>
                      <p className="profile-value">{profile?.customerReferralCode || '--'}
                        {profile?.customerReferralCode && (<CopyToClipboard
                          text={profile?.customerReferralCode}
                          options={{ format: 'text/plain' }}
                          onCopy={() => handleReferralCopy(profile?.customerReferralCode)}
                        >
                          <span className={!refeeralCopied ? 'icon md copy-icon c-pointer ms-0' : 'icon md check-icon'} />
                        </CopyToClipboard>)}
                      </p>
                    </Col>
                    <div className="copy-width">
                      <label className="profile-label">Wallet Address</label>
                      <div>
                        <span className="copy-textwdth tab-val">{address}</span>

                        <CopyToClipboard
                          text={address}
                          options={{ format: 'text/plain' }}
                          onCopy={() => handleCopy(address)}
                        >
                          <span className={!copied ? 'icon md copy-icon c-pointer ms-0' : 'icon md check-icon'} />
                        </CopyToClipboard>
                      </div>
                    </div>

                  </Row>

                  <ToastContainer className="p-3" position="bottom-center">
                    <Toast show={scuess} className="text-center toster-component">
                      <Toast.Body className="toaster-cust">
                        <span className="icon success me-2"></span>
                        <span>{success}</span>
                      </Toast.Body>
                    </Toast>
                  </ToastContainer>
                </Col></Row>
                <div className="content-green-bg">
                  <Tabs defaultActiveKey={1} className="profile-tabs matic-tab" id="fill-tab-example" fill>
                    {/* <Tab eventKey={1} title="KYC" activeKey>
                      {profile.kycStatus?.toLowerCase() !== 'completed'
                        && profile.kycStatus?.toLowerCase() !== 'pending' ? (
                        <div className="kyc-bg-card px-4">
                          <h4 className="kycnote--title">KYC Details</h4>
                          <p className="kyc-note pb-4">
                            KYC not yet completed, Please click below button to update KYC details
                          </p>
                          <div className="complete-kyc mb-3">
                            <Button
                              className="fill-btn"
                              onClick={handleKyc}
                              disabled={profile?.kycStatus?.toLowerCase() == 'completed' && true}
                            >
                              {profile?.kycStatus?.toLowerCase() == 'completed' ? 'KYC Completed' : 'Complete Your KYC'}
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <div className="">
                          {profile.kycStatus?.toLowerCase() !== 'completed' && profile.kycStatus?.toLowerCase() != 'pending' ? (
                            <h4 className="text-center">No Data</h4>
                          ) : (
                            <>
                              <div className="Personal-Details">
                                <h2 className="tab-head">Personal</h2>
                                {!loader && (
                                  <>
                                    {userDetails && (
                                      <>
                                        <div className="row mb-3">
                                          <div className="col-md-3">
                                            <p className="mb-0 tab-label">First Name </p>
                                          </div>
                                          <div className="col tab-val">
                                            {userDetails?.firstName ? userDetails?.firstName : '--'}
                                          </div>
                                        </div>
                                        <div className="row mb-3">
                                          <div className="col-md-3">
                                            <p className="mb-0 tab-label">Last Name</p>
                                          </div>
                                          <div className="col tab-val">
                                            {userDetails?.lastName ? userDetails?.lastName : '--'}
                                          </div>
                                        </div>
                                        <div className="row mb-3">
                                          <div className="col-md-3">
                                            <p className="mb-0 tab-label">Email</p>
                                          </div>
                                          <div className="col">
                                            <p className="mb-0 tab-val">
                                              {userDetails?.email ? userDetails?.email : '--'}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row mb-3">
                                          <div className="col-md-3">
                                            <p className="mb-0 tab-label">Phone No</p>
                                          </div>
                                          <div className="col">
                                            <p className="mb-0 tab-val">
                                              {userDetails?.phoneNo ? userDetails?.phoneNo : '--'}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row mb-3">
                                          <div className="col-md-3">
                                            <p className="mb-0 tab-label">Date Of Birth</p>
                                          </div>
                                          <div className="col">
                                            <p className="mb-0 tab-val">
                                              {userDetails?.dob && (
                                                <>
                                                  <Moment format="DD/MM/YYYY">{userDetails?.dob?.slice(0, 10)}</Moment>
                                                </>
                                              )}
                                              {!userDetails?.dob && '--'}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="row mb-3">
                                          <div className="col-md-3">
                                            <p className="mb-0 tab-label">Country</p>
                                          </div>
                                          <div className="col">
                                            <p className="mb-0 tab-val">
                                              {userDetails?.country ? userDetails?.country : '--'}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="row mb-3">
                                          <div className="col-md-3">
                                            <p className="mb-0 tab-label">Discord ID</p>
                                          </div>
                                          <div className="col">
                                            <p className="mb-0 tab-val word-br-id">
                                              {userDetails?.discordId ? userDetails?.discordId : '--'}
                                            </p>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="Personal-Details Identification-Details">
                                <h2 className="tab-head">Identification</h2>
                                <div className="mb-4 cust-img-render">
                              {userDetailsNestedList && userDetailsNestedList.length != 0 ? (
                                    userDetailsNestedList?.map(([idType, items]) => (
                                      <div className='passport-img'>
                                        <p className="tab-label id-label">
                                          {idType === 'PASSPORT' ? 'Passport' : idType === 'SELFIE' ? 'Selfie'
                                            : idType === "DRIVERS" ? "Driver's License" : idType === 'ID_CARD' ? 'ID Card'
                                              : idType === 'RESIDENCE_PERMIT' ? 'Residence Permit' : ""}
                                        </p>
                                        <div className="cust-kyc-img">
                                          {items?.map((item: any) => (<>

                                            <div className="identification-image ">

                                              <span className="image-box">
                                                <img
                                                  className={
                                                    item?.idType === 'SELFIE'
                                                      ? 'image-setup'
                                                      : 'id-detail-imgstyle image-setup'
                                                  }
                                                  src={item?.url || profileavathar}
                                                  alt="image"
                                                />
                                              </span>
                                            </div>
                                          </>))}
                                        </div>
                                      </div>))
                                  ) : (
                                    <div className='text-center mx-auto'>
                                      <Image width={120} src={nodata} />
                                      <p className="text-dark text-center">No data found</p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </Tab>  */}
                    <Tab eventKey={1} title="Referral’s">
                      <Referrals customerId={customerDetails?.id}></Referrals>
                    </Tab>
                    {/* <Tab eventKey={3} title="Buy Matic">
                   <Buy />
                  </Tab>  */}

                    {/* <Tab eventKey={3} title="Bonuses">
                   <Bonus />
                  </Tab> */}
                  </Tabs>
                </div>
              </>
            </Row>)}
        </Form>
      </div>
      <Modal
        show={messageShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="profile-edit"
      >
        <Form noValidate validated={validated} onSubmit={(e) => saveDetails(e)}>
          <Modal.Header>
            <Modal.Title id="example-custom-modal-styling-title">Edit Profile</Modal.Title>
            <span className="icon md close-icon-black c-pointer" onClick={handleClose}></span>
          </Modal.Header>
          <Modal.Body>
            {errorMsg && (
              // <Alert variant="danger">
              //   <p style={{ color: 'red', margin: 10 }}>{errorMsg}</p>
              // </Alert>
              <div className='cust-error-bg'>
                <div className='cust-crd-mr'>
                  <img src={error}></img>
                </div>
                <div>
                  <p className='error-title error-red'>Error</p>
                  <p className='mb-0'>{errorMsg}</p>
                </div>
              </div>
            )}
            <div className="" id="profile">
              <div className="row">
                <div className="col-md-6">
                  <FloatingLabel controlId="floatingInput" label="Nickname " className="input-style">
                    <Form.Control
                      type="text"
                      name="nickName"
                      value={form?.nickName}
                      onChange={(e) => {
                        setField('nickName', e.currentTarget.value);
                      }}
                      isInvalid={!!errors.nickName}
                      placeholder="Nickname "
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.nickName}</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel controlId="floatingInput" label="First Name *" className="input-style">
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={form?.firstName}
                      onChange={(e) => {
                        setField('firstName', e.currentTarget.value);
                      }}
                      isInvalid={!!errors?.firstName}
                      required
                      placeholder="First Name *"
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.firstName}</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel controlId="floatingInput" label="Last Name *" className="input-style">
                    <Form.Control
                      type="text"
                      name="lastName"
                      value={form?.lastName}
                      onChange={(e) => {
                        setField('lastName', e.currentTarget.value);
                      }}
                      isInvalid={!!errors?.lastName}
                      required
                      placeholder="Last Name *"
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.lastName}</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel controlId="floatingInput" label="Email *" className="input-style">
                    <Form.Control
                      type="text"
                      name="email"
                      value={form?.email}
                      onChange={(e) => {
                        setField('email', e.currentTarget.value);
                      }}
                      isInvalid={!!errors?.email}
                      required
                      placeholder="Email *"
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.email}</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel controlId="floatingInput" className="input-style">
                    <InputGroup className="mb-2 input-style no-wrap mobile-noinput">
                      <Form.Control
                        required
                        as="select"
                        type="select"
                        name="country"
                        className="code-width c-pointer"
                        aria-label="Default select example"
                        onChange={(e) => {
                          setField('countryCode', e.currentTarget.value);
                        }}
                        value={form?.countryCode}
                        isInvalid={!!errors?.countryCode}
                      >
                        <option>select</option>
                        {phoneCOde.map((item, index) => (
                          <>
                            <option value={item.code} key={index}>{item.code} </option>
                          </>
                        ))}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">{errors?.countryCode}</Form.Control.Feedback>
                      <label className="floatingInput-number cust-zindex">Phone No*</label>
                      <Form.Control
                        type="text"
                        className="form-number"
                        name={'Gold'}
                        onChange={(e) => {
                          setField('phoneNo', e.currentTarget.value);
                        }}
                        isInvalid={!!errors?.phoneNo}
                        value={form?.phoneNo}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        required
                        autoComplete="off"
                        maxLength={12}
                      />
                      <Form.Control.Feedback type="invalid" className="">
                        {errors?.phoneNo}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Country *"
                    className="mb-4 input-style country-code-style"
                  >
                    <Form.Control
                      required
                      as="select"
                      type="select"
                      name="country"
                      className="input-style c-pointer"
                      aria-label="Default select example"
                      value={form?.country}
                      onChange={(e) => {
                        setField('country', e.currentTarget.value);
                      }}
                      isInvalid={!!errors?.country}
                    >
                      <option>Select Country</option>
                      {jsonCountryCode.map((item) => (
                        <option>{item.name}</option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors?.country}</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel controlId="floatingInput" label="Discord ID*" className="input-style">
                    <Form.Control
                      type="text"
                      name="Discord"
                      value={form?.discordId}
                      onChange={(e) => {
                        setField('discordId', e.currentTarget.value);
                      }}
                      isInvalid={!!errors?.discordId}
                      required
                      placeholder="Discord ID*"
                      maxLength={50}
                    />
                    <Form.Control.Feedback type="invalid">{errors?.discordId}</Form.Control.Feedback>
                  </FloatingLabel>
                </div>
                <div className="col-md-6">
                  <FloatingLabel controlId="floatingInput" label="Referral Code" className="input-style position-relative">
                    <Form.Control
                      type="text"
                      name="referralCode"
                      // required
                      value={form?.referralCode}
                      placeholder="Referral Code"
                      maxLength={6}
                      onChange={(e) => { setField('referralCode', e.currentTarget.value) }}
                      disabled={profile?.referralCode != null}
                    />
                    <div className='referral-check'>
                      <p className='mb-0'>{touched?.name}</p>
                      {touched?.isReferral && <span className='icon sm check-circle mt-0 position-absolute'></span>}
                    </div>

                    {/* <Form.Control.Feedback type="invalid"></Form.Control.Feedback> */}
                  </FloatingLabel>
                </div>
                {!profile?.referralCode && !touched?.isReferral && <div className="col-md-12 text-end">
                  <Button type="button"
                    onClick={() => getIsExistingReferralCOde()} className="fill-btn m-0  submit-spinner">
                    <span>{referralloader && <Spinner className='custmint-loader' size="sm" />} </span> Verify
                  </Button>
                </div>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="mt-4 text-end">
              <Button type="button" className="border-btn m-0 text-center btn-cancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button type="submit" className="fill-btn m-0 ms-3 submit-spinner" disabled={loader}>
                {loader && <Spinner className='custmint-loader ' size="sm" />} Save
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
};

const connectStateToProps = ({ auth }) => {
  return { user: auth?.user };
};

export default connect(connectStateToProps)(ProfileView);
