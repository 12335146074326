import React,{useEffect} from 'react';
import {useSelector,connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { getWhiteListData, setUserID } from '../../reducer/rootReducer';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import Image from 'react-bootstrap/Image';
import nodata from '../../assets/images/no-data.png';
import daoName from '../../assets/images/dao.svg';
import Moment from "react-moment";
import moment from 'moment';
import loadimg from '../../../src/assets/images/Minnapad-Logo.svg'
import error from '../../../src/assets/images/error.svg';
import { store } from '../../store';
import { getCUstomers } from '../../utils/apiGet';
import { useAccount } from 'wagmi';


 const  Whitelisting=(props : any)=>{
  const authInfo = useSelector((state: any) => state.auth.user.id);
  const referralInfo = useSelector((state: any) => state.auth.user.id);
  const memberType = useSelector((state: any) => state.auth.fetchwhitelistingdata);
  const router = useNavigate();
  const { address } = useAccount();
  useEffect(()=>{
    getCustomerDetail()
  props.getWhiteListData(authInfo);
  },[authInfo])
 
  const getCustomerDetail = async () => {
    let response = await getCUstomers(`User/CustomerDetails/${address}`);
    if (response) {
      if(!response.data.isReferralPage && response.data.kycStatus?.toLowerCase() == 'completed'){
        store.dispatch(setUserID(response.data));
        router(`/minnapad/referrals`)
      }
    }
  };

  const gotoWhitelistingData=(item : any)=>{
	if(item?.type != "KOL" && item.taskCount != 0){
		router(`taskcount/${item?.id}/${authInfo}`);
	}
  }

  return (
    <>
      <div className="container">
	  
        <div className='table-width card-mt-pt'>
        {memberType?.error && (
            <div className='cust-error-bg'>
            <div className='cust-crd-mr'><Image src={error} alt="" /></div>
            <div>
            <p className='error-title error-red'>Error</p>
            <p className="error-desc">{memberType?.error}</p></div></div>
          )}
          <h3 className='detailview-title text-center'>Whitelisting's</h3>
          <hr className='my-collection-hr' />
		  <div className="text-center whitelist-scroll">{memberType?.isLoading ?
       <div className='loading-overlay'><div className="text-center image-container">          
          <Image
            className=""
            src={loadimg}
            alt=""
          />
            </div></div>: <>
              {memberType?.data?.data?.length > 0 ?
                <>
                  {memberType?.data?.data?.map((item: any, index: any) => <div>
                    <div className='whitelist-rowcard mb-3'>
                      <div className='flex-width text-start'><Image src={item.daoImage} alt="" className='img-mr' /><span className='dao-name'>{item.daoName}</span></div>
                      <div className='flex-width'><p className='label-type'>Date</p><p className='label-value'>
                        <Moment format='DD/MM/YYYY'>{moment(new Date(item.date), 'DD/MM/YYYY')}</Moment></p></div>
                      <div className='flex-width'><p className='label-type'>Type</p><p className='label-value'>{item.period}</p></div>
                      <div className='flex-width'><p className='label-type'>Tasks</p><span className='label-value hover-value c-pointer' onClick={() => gotoWhitelistingData(item)}>{item.taskCount}</span><span className='icon sm expand c-pointer' onClick={() => gotoWhitelistingData(item)}></span></div>
                      <div className='flex-width'><p className='label-type'>Status</p><p className='label-value'>{item.status}</p></div>
                    </div>

                  </div>)}
                </> :
                <div className="text-center" style={{ flex: '1' }}>
                  <img src={nodata} style={{ width: '95px' }}></img>
                  <h1 className='mt-2' style={{ color: '#000', flex: '100%', fontSize: '22px' }} >
                    No Collections Found
                  </h1></div>
              }
     </>}


          
          
          </div>
       
          </div>
        {/* </div> */}
      </div>
    </>
  );
}
const connectDispatchToProps = (dispatch: any) => {
	return {
		getWhiteListData: (id : any) => {
		dispatch(getWhiteListData(id));
	  },
	  dispatch,
	};
  };
  
  export default connect(null, connectDispatchToProps)(Whitelisting);
