import React,{useEffect,useRef,useState} from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { getWhiteListDetails } from '../../reducer/rootReducer';
import { connect, useSelector } from 'react-redux';
import { useNavigate,useParams } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import { useAccount } from 'wagmi';
 function TaskCount(props : any) {
  const router = useNavigate();
  const shouldLog = useRef(true);
  const params = useParams()
  const memberType = useSelector((state: any) => state.auth.fetchWhitelistDetails);
  const [remarks,setRemarks]=useState(null)
  const authInfo = useSelector((state: any) => state.auth.user.id);
  
  const { connector: activeConnector } = useAccount();
  
  useEffect(()=>{
    setRemarks(memberType?.data?.remarks)
    if (params?.id && shouldLog.current) {
      shouldLog.current = false;
     props.getWhiteListDetails(params.id,params.whiteListId)
    }
   
  },[params.id,memberType])

 useEffect(() => {
		const handleConnectorUpdate = ({ account, chain }: ConnectorData) => {
			if (account) {
        router('/minnapad/whitelisting')
			} else if (chain) {
			}
		};

		if (activeConnector) {
			activeConnector.on("change", handleConnectorUpdate);
		}

		return () => activeConnector?.off("change", handleConnectorUpdate) as any;
	}, [activeConnector]);


  const handleRedirect = () => {
    router('/minnapad/whitelisting')
  }

  const taskData= memberType?.data?.taskData;
  const keys = taskData ? Object.keys(taskData) : [];

  return (
    <>
      <div className="container cust-container-align">
        {memberType?.error && (
            <Alert variant="danger">
              <p style={{ color: 'red', margin: 10 }}><span className="icon error-alert me-2"></span>{memberType?.error}</p>
            </Alert>
          )}
          <div className='card-mt-pt'>
            <h3 className='detailview-title text-center'>Whitelisting's</h3>
            <hr className='my-collection-hr' />
            <div className='whitelist-content task-countbg'>
            <div className="text-center">{memberType?.isLoading && <Spinner></Spinner>}</div>
            {keys?.map((key) => (
								<Col key={key}  xl={12} sm={12} md={12} className='mb-4'>
								{typeof taskData[key] === 'object' ? (
									Object.keys(taskData[key]).map((nestedKey,nestedValue) => (
									<div key={nestedKey} value={nestedValue}>
									<p className="profile-value mb-0">{nestedKey}</p>
                  <Form.Control type="text" size="lg" placeholder="Handler" disabled={true} defaultValue={taskData[key][nestedKey]} className='placeholder-style' />
									</div>
									))
								) : (
									<div>
									<Form.Label className='price-label'>{key}</Form.Label>
									</div>
								)}
								</Col>
							))}
              <Col lg={12} md={12}>
              <p className='profile-value mb-0'>Remarks</p>
						
						<Form.Control defaultValue={remarks} rows={3} name='remarks' as="textarea" placeholder="Remarks"
						 maxLength={250} 
             className='placeholder-style'
             disabled={true}
					 />
						<Form.Control.Feedback type="invalid">Please enter valid content</Form.Control.Feedback>
						
						</Col>
         
            <div className='mt-3 text-end'>
            <Button className="border-btn m-0 text-center btn-cancel" onClick={()=>handleRedirect()}>Close</Button>
            </div>
        </div>
        </div>
        </div>
    </>
  );
}
const connectDispatchToProps = (dispatch: any) => {
  return {
    getWhiteListDetails: (id : any,whitListId :any) => {
      dispatch(getWhiteListDetails(id,whitListId));
    },
    dispatch,
  };
};

export default connect(null, connectDispatchToProps)(TaskCount);