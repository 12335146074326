import React, { useEffect, useState } from 'react';
import { getReferralList } from "../../reducers/authReducer"
import { useSelector, connect, } from 'react-redux';
import { getReferralsData,getEarnedBonous } from '../../utils/apiGet';
import Moment from "react-moment";
import moment from 'moment';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button} from "react-bootstrap";
import nodata from '../../assets/images/no-data.png';
import { useAccount } from 'wagmi';
import {Image} from 'react-bootstrap';
import loadimg from '../../assets/images/Minnapad-Logo.svg'

function Referrals(props:any) {

  const [refferalData,setRefferalData] = useState([])
  const [copied, setCpoied] = useState(false);
  const [referralCopied, setReferralCpoied] = useState(false);
  const authInfo = useSelector((state: any) => state.auth.user);
  const [loader, setLoader] = useState(false);
  const pageSize = 10;
  const [pageNo, setPageNo] = useState(1);
  const [errorMsg, setErrorMsg] = useState(false);
  const [loadData,setLoadData]=useState();
  const { address } = useAccount();
  const [earnedValue,setEarnedValue]=useState({})
  const [walletId,setWalletId] = useState({})
  const [pageloader, setPageLoader] = useState(false);
  useEffect(() => { 
  if(props?.user?.id || props?.customerId){
   getRefferalData(1,10);
   getEarnedData()
  }
}, [address]);


const isErrorDispaly = (objValue) => {
  if ((objValue.status > 400 && objValue.status < 500) && objValue.status != 401) {
      return "Something went wrong please try again!";
    } else {
      if (objValue.data && typeof objValue.data === "string") {
        return objValue.data;
      } else if (objValue.response.data && objValue.response.data.title && typeof objValue.response.data.title) {
        return objValue.response.data.title;
      } else if (
        objValue.originalError &&
        typeof objValue.originalError.message === "string"
      ) {
        return objValue.originalError.message;
      } else {
             return typeof (objValue) === "object" && objValue.reason ? objValue.reason : "Something went wrong please try again!";
      }
    }
  };

const getEarnedData=async()=>{
  await getEarnedBonous(`User/customerearned/${authInfo?.id}`)
  .then((response)=>{
    setEarnedValue(response.data)
  }).catch(
    (error) => {
      setErrorMsg(isErrorDispaly(error));
      setLoader(false);
    }
  )

}

  const getRefferalData = async (pageNo: any, pageSize: any) => {
    setPageLoader(true);
    if (refferalData.length == 0) {
      setLoader(true);
      setPageLoader(false);
    }
    const skip = pageNo * pageSize - pageSize;
    const take = pageSize;
    await getReferralsData(`User/GetReferralData/${authInfo?.id}/${take}/${skip}`)
      .then((response) => {
        let _pageNo = pageNo + 1;
        setPageNo(_pageNo);
        let mergeData = pageNo == 1 ? [...response.data] : [...refferalData, ...response.data];
        setRefferalData(mergeData)
        setLoadData(response.data?.length>=10)
        setLoader(false);
        setPageLoader(false);
      })
      .catch((error) => {
        setErrorMsg(isErrorDispaly(error));
        setLoader(false);
        setPageLoader(false);
      });
  };

  const loadMore = () => {
    getRefferalData(pageNo, pageSize);
  }
const handleCopy = (item:any) => {
  setWalletId(item?.walletAddress)
  setCpoied(true);
  setReferralCpoied(false)
  setTimeout(() => setCpoied(false), 1000);
};
const handleReferralCopy = () => {   
  setReferralCpoied(true)
  setCpoied(false);
  setTimeout(() => setReferralCpoied(false), 1000);
};
  return (
    <>
    <div className='container'>
    {pageloader &&
                    <div className='text-center'>
                        <div className='loading-overlay'>
                            <div className="text-center image-container">
                                <Image
                                    className=""
                                    src={loadimg}
                                    alt=""
                                />
                            </div></div></div>}
      <div>

    <div className='Personal-Details '>  
          <div className='lg-d-flex'>
            <div className='mt-2 mb-2'>
              <span className='profile-label '>My Referral Code :  </span>
              <span className='profile-value'>{authInfo?.customerReferralCode || "--"}</span>
              {authInfo?.customerReferralCode && (<CopyToClipboard
                text={authInfo?.customerReferralCode}
                options={{ format: 'text/plain' }}
                onCopy={() => handleReferralCopy(authInfo?.customerReferralCode)}
              >
                <span className={!referralCopied ? 'icon md copy-icon c-pointer ms-0' : 'icon md check-icon'} />
              </CopyToClipboard>)}

            </div>
            <div className='mt-2 mb-2 d-flex my-earnings lg-ms-3 sm-ms-0'>
              <span className='profile-label '>My Earnings :  </span>
              {/* <span className='profile-value'>{authInfo?.customerReferralCode || "--"}</span> */}
              <div className='lg-d-flex align-items-center'>
              <div>
                {/* <span className='profile-label ms-1'>Matic :- </span>
                <span className='profile-value'>{ earnedValue?.value|| "-"}</span>
                <span className='profile-value'>{ earnedValue?.maticCoin|| "-"}</span> */}
                <span className='profile-label ms-1'>Matic :- </span>
                {earnedValue?.value  !="0.00000000"?
                 <span className='profile-value'>{ earnedValue?.value} { earnedValue?.maticCoin}</span>
                 :<span className='profile-value'>{"-"}</span>}

              </div>
              <div>
                <span className='profile-label lg-ms-3'>ETH :- </span>
                {earnedValue?.ethValue !="0.00000000"?
                 <span className='profile-value'>{ earnedValue?.ethValue} { earnedValue?.ethCoin}</span>
                 :<span className='profile-value'>{"-"}</span>}
                
                
              </div>
              </div>


            </div>
          </div>
          <h1 className='main-title mb-3'>Referral’s</h1>
    </div>
      <div className='sm-auto'>
      
       {refferalData.length > 0 ?  <div>
          {refferalData?.map((item:any)=>(         
          <div className='whitelist-rowcard mb-3'>
            <div className='flex-width text-start'>
              <p className='label-type'>Date</p>
              <p className='label-value'>
              <Moment format='DD/MM/YYYY'>{moment(new Date(item?.date),'DD/MM/YYYY')}</Moment>

              </p>
            </div>
            <div className='flex-width'>
              <p className='label-type'>Name</p>
              <p className='label-value'>{(item?.name ==" " || !item.name)? "-" : item?.name}</p>
            </div>
            <div className='flex-width'>
              <p className='label-type'>Wallet Address</p>
              <div className='label-value wallet-address'>
                {item?.walletAddress} 
                <CopyToClipboard
              text={item?.walletAddress}
              options={{ format: 'text/plain' }}
              onCopy={() => handleCopy(item)}
            >
              <span className={(copied && item?.walletAddress === walletId) ? 'icon md check-icon' : 'icon md copy-icon c-pointer ms-0'} />
            </CopyToClipboard>
                </div>
            </div>
            {/* <div className='flex-width'>
              <p className='label-type'>Is Membership</p>
              <p className='label-value'>{item?.isMemberShip?.toString()}</p>
            </div> */}
            <div className='flex-width'>
              <p className='label-type'>Bonus</p>
              <p className='label-value'>{(!item?.value || item?.value =="0") ? "-" : item?.value + " " + item?.coin }</p>
              <p className='label-value'>{(!item?.ethValue || item?.ethValue =="0") ? "" : item?.ethValue + " " + item?.ethCoin }</p>
            </div>
          </div> 
           ))}
           {loadData &&(
            <div className="category-more text-center">
              <p onClick={loadMore} className="c-pointer more-hover mb-0">See More</p>
              <div className="doenarrow-icon">
                <span className="icon md see-more c-pointer" onClick={loadMore}></span>
              </div>
            </div> )}
          
        </div> :
        <div className='text-center'>
        <img src={nodata} width={100}></img>
         <h4 className="nodata-text">No Data Found</h4>
        </div>}
      </div></div>
      
      </div>
    </>
  );
}

const connectDispatchToProps = (auth:any) => {
  return {  user: auth?.user}
}

 export default connect(null,connectDispatchToProps)(Referrals);