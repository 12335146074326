import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import logoWhite from '../../../assets/images/Minnapad-Logo-hor.svg';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getCUstomers } from '../../../utils/apiGet';
import { store } from '../../../store';
import { selectedCollection, setIscustomerRegister, setUserID } from '../../../reducer/rootReducer';
import { supportedChains } from '../../../utils/supported.chains';
import { useDispatch } from 'react-redux';
import MetamaskIco from '../../../assets/images/pop-icon1.svg';
import WalletConnectIco from '../../../assets/images/walletconnect.svg';
import TrustWalletIco from '../../../assets/images/connectwallet.svg';
import ToasterMessage from '../../../utils/tostermessage';
import { switchNetwork } from 'wagmi/actions';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { changeNetwork } from '../hooks/useChangeChain';
function Header(props: any) {
  const [modalShow, setModalShow] = React.useState(false);
  const { connectAsync, connectors } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { isConnected, address } = useAccount();
  const router = useNavigate();
  const currentRoute = window.location.pathname;
  const [loader, setLoader] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const authInfo = useSelector((state: any) => state.auth);
  const [walletError, setWalletError] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    store.dispatch(setIscustomerRegister({ key: 'customerRegisterDetails', data: null }));
    if (isConnected==true && address) {
      getCustomerDetail(address, false);
    } else {
      setUserID({ id: '', name: '' });
    }
  }, []);

  useEffect(() => {
    const _connector: any = window?.ethereum;
    if(_connector){        
    _connector.on('chainChanged', async (chain_id) => {
        if (!supportedChains.includes(chain_id)) {
            await disconnectAsync();
            router('/minnapad/dashboard');
        }
    });
    _connector.on('accountsChanged', async (account) => {
      if (!isConnected) {
        await connectAsync( {connector: new MetaMaskConnector()});
        getCustomerDetail(account[0],true)
      } else {
        getCustomerDetail(account[0],true)
      }     
    });
}
}, [address]);


  const getCustomerDetail = async (address, isAccountChange) => {
    setLoader(true);
    store.dispatch(setIscustomerRegister({ key: 'customerRegisterDetails', data: null }));
    
      let response = await getCUstomers(`User/CustomerDetails/${address}`);

      if (address !=undefined && response) {
        store.dispatch(setUserID(response.data));
        if (response.data.kycStatus == null && response.data.kycStatus?.toLowerCase() !== 'completed') {
          router('/minnapad/kycStatus');
        }
         else if (!response.data.isReferralPage && response.data.kycStatus?.toLowerCase() == 'completed')
        {
          store.dispatch(setUserID(response.data));
          router(`/minnapad/referrals`)
        } 
        else if(isAccountChange) {
          router('/minnapad/dashboard');
        }
        authToken(response.data);
        store.dispatch(setUserID(response.data));
        store.dispatch(selectedCollection(response.data.imageUrl));
        setLoader(false);
      } else {
        setErrorMsg(isErrorDispaly(response));
        setLoader(false);
        router('/minnapad/dashboard');
      }
   
  };
  const authToken = (data: any) => {
    const payload = {
      id: data.id,
      email: data.email,
    };
  };
  const isErrorDispaly = (objValue: any) => {
    if (objValue.data && typeof objValue.data === 'string') {
      return objValue.data;
    } else if (objValue.originalError && typeof objValue.originalError.message === 'string') {
      return objValue.originalError.message;
    } else {
      return 'Something went wrong please try again!';
    }
  };
  const handleDisconnect = async () => { 
    setWalletError(null);
    await disconnectAsync();
    dispatch(setIscustomerRegister({ key: 'customerRegisterDetails', data: null }));
    router('/minnapad/dashboard');
  };
  const handleRedirect = (path: string) => {
    if (isConnected) {
      if (!authInfo.user.kycStatus || authInfo.user.kycStatus.toLowerCase() !== 'completed') router('/minnapad/kycStatus');
      else router(path);
    }
  };
  const redirectToMintingScreen = () => {
    window.open(process.env.REACT_APP_LINK_MINNAPAD, '_blank');

  }
  const homeRedirect = (path) => {
    if (isConnected) {
      if (!authInfo.user.kycStatus || authInfo.user.kycStatus.toLowerCase() !== 'completed') router('/minnapad/kycStatus');
      else router(path);
    } else {
      router(path);
    }
  }
  const daoRedirect = (key: string) => {
    const _links = {
      dao: process.env.REACT_APP_LINK_MINNAPAD_DAO,
      blog: process.env.REACT_APP_LINK_MINNAPAD_BLOGS,
      enquiry: process.env.REACT_APP_LINK_MINNAPAD_INQUIRIES,
      joinus: process.env.REACT_APP_LINK_MINNAPAD_JOINUS
    }
    window.open(_links[key], '_blank');
  }
  const handleConnect = async ({ connector }) => {
    if (connector.id === "walletConnect") {
      setModalShow(false)
    }
    try {
      const { account, chain } = await connectAsync({ connector });
      if (!supportedChains.includes(chain.id)) {
        try {
          const network = await switchNetwork({ chainId: supportedChains[1] });
          setLoader(true);
          setModalShow(false);
          getCustomerDetail(account, false);
          connector.onAccountsChanged = (accounts) => {
            getCustomerDetail(accounts[0], true);
          }
          connector.onChainChanged = async (id) => {
            await disconnectAsync();
            router("dashboard");
          }
        } catch (error) {
          await disconnectAsync();
        }
      } else {
        setLoader(true);
        setModalShow(false);
        getCustomerDetail(account, false);
        connector.onAccountsChanged = (accounts) => {
          getCustomerDetail(accounts[0], true);
        }
        connector.onChainChanged = async (id) => {
          await disconnectAsync();
          router("dashboard");
        }
      }
      setWalletError(null);
    } catch (error) {
      setWalletError(error.message)
      router("dashboard");
    }
  };

  const walletIcons = {
    metaMask: MetamaskIco,
    walletConnect: WalletConnectIco,
    injected: TrustWalletIco
  }
  const handleClose = () => {
    setModalShow(false)
  }

  const handleMinnapad = () => {
    window.open(process.env.REACT_APP_BRAND_WEBSITE, "_blank")
  };
  const handleMarketPlace = () => {
    window.open(process.env.REACT_APP_LINK_MARKET_PLACE, "_blank")
  };
  
  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="wallet-popup"
      >
        <Modal.Header >
          <span className="icon md close-icon-black c-pointer" onClick={handleClose}></span>
        </Modal.Header>
        <Modal.Body className="text-center p-3">
          <h4>Connect Your Wallet</h4>
          <hr className="modal-hr"></hr>
          <p>
            Connect with one of available wallet providers or create
            a new wallet.
          </p>
          <div className='custom-metamask-btns'>
            {connectors.map((connector) => (<div className="top d-flex" key={connector.id} >
              {connector.ready && <Button className="pop-cards top d-flex c-pointer meta-btn-style" type="button"
                onClick={() => handleConnect({ connector })}
              >
                <Image src={walletIcons[connector.id]} alt="" />
                <p>{connector.name}</p>
              </Button>}</div>
            ))}
          </div>
          <p className="bottom-para mb-2">
            we do not own private keys and cannot access your funds without your confirmation
          </p>
          <p className='mt-4'><b>Note: If you encounter difficulties connecting your wallet, please refresh your browser and try again.</b></p>
        </Modal.Body>
      </Modal>
      <div className="cust-toaster-db" >
        <ToasterMessage success={walletError} bg={"Danger"} isShowToaster={walletError != null} onCloseToast={() => setWalletError(null)} />
      </div>
      <Navbar className="text-white cust-head-active custom-right mobile-header-fixed" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand href="" style={{ cursor: "pointer" }}
            onClick={() => redirectToMintingScreen()}
            className={currentRoute === '/minnapad/home' ? 'nav-link' : 'nav-link '}>
            <Image src={logoWhite} alt="logo" className="cust-logo" />
          </Navbar.Brand>
          {!isConnected && (<div className="btn-leftspace mobile-show">
            {!isConnected && (
              <Button className="head-btn" onClick={() => setModalShow(true)}>
                {!isConnected && (
                  <>
                    <span className="icon md wallet"></span>
                    <span>Connect Wallet</span>
                  </>
                )}
              </Button>
            )}
          </div>)}
          {isConnected && (<div className="btn-leftspace profile-dropdwn mobile-show">
            <NavDropdown
              className="p-0"
              title={
                isConnected && (
                  <>
                    <Button className="head-btn">
                      <span className='icon md wallet'></span>
                      <span>
                        {address?.slice(0, 4) + '....' + address?.substring(address.length - 4, address.length)}
                      </span>
                    </Button>
                  </>
                )
              }
              id="collasible-nav-dropdown"
            >
              <Nav.Link
                onClick={() => handleRedirect('/minnapad/profile')}
                className={currentRoute === '/minnapad/profile' ? 'nav-link active m-0' : 'nav-link cust-link-nav m-0'}
              >
                <NavDropdown.Item><span className='icon md profile'></span><span> Profile</span> </NavDropdown.Item>
              </Nav.Link>
              <Nav.Link
                onClick={() => handleRedirect('/minnapad/whitelisting')}
                className={currentRoute === '/minnapad/whitelisting' ? 'nav-link active' : 'nav-link cust-link-nav'}
              >
                <NavDropdown.Item><span className='icon md whitelist'></span> <span>Whitelisting</span> </NavDropdown.Item>
              </Nav.Link>
              <Nav.Link
                className={currentRoute === '/minnapad/mycollections' ? 'nav-link active m-0' : 'nav-link cust-link-nav m-0'}
                onClick={() => handleRedirect('/minnapad/mycollections')}
              >
                <NavDropdown.Item><span className='icon md my-collect'></span><span> My Collections</span> </NavDropdown.Item>
              </Nav.Link>
              <Nav.Link
                className="nav-link cust-link-nav m-0"
                onClick={() => handleRedirect()}
              >
                <> {isConnected && (<NavDropdown.Item onClick={handleDisconnect}>
                  < >
                    {isConnected && (
                      <>
                        <span className='icon md wallet'></span>
                        <span className='ms-1'>Disconnect</span>
                      </>
                    )}
                  </></NavDropdown.Item>
                )}</>
              </Nav.Link>
            </NavDropdown>
          </div>)}
          <Navbar.Toggle aria-controls="navbarScroll" />

          {/* </div> */}
          <Navbar.Collapse id="navbarScroll"   >
            <Nav className="text-white my-2 my-lg-0 navbar-nav custom-right" navbarScroll>
              <Nav.Link
                href=""
                  onClick={handleMinnapad}
                // onClick={() => homeRedirect('/minnapad/dashboard')}
                // className={currentRoute === '/minnapad/dashboard' ? 'nav-link active' : 'nav-link '}
              >
                {/* <Link className={currentRoute == '/minnapad/dashboard' ? 'nav-link active' : 'nav-link '} to={'/minnapad/dashboard'}> */}
                  Home
                {/* </Link> */}
              </Nav.Link>
              <Nav.Link
                href=""
                onClick={() => daoRedirect('dao')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link '}
              >
                <Link className={currentRoute == '' ? 'nav-link active sm-p-0' : 'nav-link sm-p-0'}>
                  DAOs
                </Link>
              </Nav.Link>

              <Nav.Link
                href=""
                onClick={handleMarketPlace}
                // onClick={() => homeRedirect('/minnapad/dashboard')}
                // className={currentRoute === '' ? 'nav-link active' : 'nav-link'}
              >
                {/* <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link header-nav-tab'}> */}
                  Marketplace
                {/* </Link> */}
              </Nav.Link>
              {/* <Nav.Link
                href=""
                onClick={() => daoRedirect('enquiry')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link'}
              >
                <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link header-nav-tab'}>
                  Inquiries
                </Link>
              </Nav.Link> */}
              {/* <Nav.Link
                href=""
                onClick={() => daoRedirect('blog')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link'}
              >
                <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link header-nav-tab'}>
                  Blog
                </Link>
              </Nav.Link> */}
              {/* <Nav.Link
                href=""
                onClick={() => daoRedirect('joinus')}
                className={currentRoute === '' ? 'nav-link active' : 'nav-link'}
              >
                <Link className={currentRoute == '' ? 'nav-link active' : 'nav-link header-nav-tab'}>
                  Join Us
                </Link>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
          <div className="btn-leftspace mobile-hidden">
            {!isConnected && (
              <Button className="head-btn" onClick={() => setModalShow(true)}>
                {!isConnected && (
                  <>
                    <span className="icon md wallet"></span>
                    <span>Connect Wallet</span>
                  </>
                )}
              </Button>
            )}
          </div>
          <div className="btn-leftspace profile-dropdwn ms-0 mobile-hidden">
            <NavDropdown
              className="p-0"
              title={
                isConnected && (
                  <>
                    <Button className="head-btn">
                      <span className='icon md wallet'></span>
                      <span>
                        {address?.slice(0, 4) + '....' + address?.substring(address.length - 4, address.length)}
                      </span>
                    </Button>
                  </>
                )
              }
              id="collasible-nav-dropdown"
            >
              <Nav.Link
                onClick={() => handleRedirect('/minnapad/profile')}
                className={currentRoute === '/minnapad/profile' ? 'nav-link active m-0' : 'nav-link cust-link-nav m-0'}
              >
                <NavDropdown.Item><span className='icon md profile'></span><span> Profile</span> </NavDropdown.Item>
              </Nav.Link>
              <Nav.Link
                onClick={() => handleRedirect('/minnapad/whitelisting')}
                className={currentRoute === '/minnapad/whitelisting' ? 'nav-link active' : 'nav-link cust-link-nav'}
              >
                <NavDropdown.Item><span className='icon md whitelist'></span> <span>Whitelisting</span> </NavDropdown.Item>
              </Nav.Link>
              <Nav.Link
                className={currentRoute === '/minnapad/mycollections' ? 'nav-link active m-0' : 'nav-link cust-link-nav m-0'}
                onClick={() => handleRedirect('/minnapad/mycollections')}
              >
                <NavDropdown.Item><span className='icon md my-collect'></span><span> My Collections</span> </NavDropdown.Item>
              </Nav.Link>
              <Nav.Link
                className="nav-link cust-link-nav m-0"
                onClick={() => handleRedirect()}
              >
                <> {isConnected && (<NavDropdown.Item onClick={handleDisconnect}>
                  < >
                    {isConnected && (
                      <>
                        <span className='icon md wallet'></span>
                        <span className='ms-1'>Disconnect</span>
                      </>
                    )}
                  </></NavDropdown.Item>
                )}</>
              </Nav.Link>
            </NavDropdown>
          </div>
        </Container>
      </Navbar>

    </>
  );
}
const connectStateToProps = ({ auth, SET_ISCUSTOMER_REGISTER }) => {
  return { auth: auth, SET_ISCUSTOMER_REGISTER: SET_ISCUSTOMER_REGISTER };
};
export default connect(connectStateToProps, (dispatch) => {
  return { dispatch };
})(Header);
