import React from "react"
import Routes from "./routes"

const Content = () => {
    return <>
        <Routes />
    </>


}

export default Content