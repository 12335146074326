
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./assets/css/light-theme.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/custom-styles.css";
import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { InjectedConnector } from 'wagmi/connectors/injected';
import { polygon } from 'viem/chains';
import { Web3Modal } from '@web3modal/react';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';
import { amoyNetwork } from './utils/amoyNetwork';
const walletConnectProjectId: any = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID;
const { chains, publicClient } = configureChains(
  [polygon, amoyNetwork],
  [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: process.env.REACT_APP_ENV === "production" ? `https://polygon.llamarpc.com` : `https://rpc-amoy.polygon.technology`,
      }),
    }),
  ],
);
const config = createConfig({
  autoConnect: true,
  connectors: [
    new InjectedConnector({
      chains: chains, options: {
        name: "Browser Wallet"
      }
    }),
    new WalletConnectConnector({
      chains: chains,
      options: {
        projectId: walletConnectProjectId,
        metadata: {
          name: "Minnapad",
          description: "Minnapad is a Create-to-Earn DAOs' launchpad and NFT marketplace that provides access to legendary Japanese creators for the wider entertainment community.",
          icons: ["https://devmpstorageaccount.blob.core.windows.net/minnapad/minnapad-color-logo.svg"],
          url: "https://minnapad.com"
        }
      }
    })
  ],
  publicClient
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiConfig config={config}>
      <App />
      <Web3Modal
        themeVariables={{
          '--w3m-z-index': "9999",
          '--w3m-background-color': '#0067FD'
        }}
        projectId={walletConnectProjectId}
      />
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
