import { get } from '../utils/apiGet';
import {post} from "../utils/api"
const SET_USER_ID: string = 'setUserID';
const SELECTED_COLLECTION: any = 'selectedCollection';
const SELECTED_EDIT_DATA:any='selectedEditData';
const HANDLE_FETCH_METADATA = 'handleFetchMetaData';
const HANDLE_FETCH_MEMBERTYPE = 'handleFetchMemberType';
const HANDLE_FETCH_MEMBERPRICE = 'handleFetchMemberPrice';
const SET_ISCUSTOMER_REGISTER="setIscustomerRegister";
const SET_CUSTOMER_REGISTER="setCustomerRegister";
const FETCH_WHITELISTING_DETAILS = "fetchWhitelistDetails";
const FETCH_WHITELISTING_DATA = "fetchwhitelistingdata";


const selectedEditData = (payload:any) => {
  return {
    type: SELECTED_EDIT_DATA,
    payload,
  };
};

const setUserID = (payload: any) => {
  return {
    type: SET_USER_ID,
    payload,
  };
};

const setIscustomerRegister=(payload)=>{
  return {
    type: SET_ISCUSTOMER_REGISTER,
    payload,
  };
}

const setCustomerRegister=(payload)=>{
  return {
    type: SET_CUSTOMER_REGISTER,
    payload,
  };
}

const selectedCollection = (payload: string) => {
  return {
    type: SELECTED_COLLECTION,
    payload,
  };
};

const handleFetchMetaData = (payload) => {
  return {
    type: HANDLE_FETCH_METADATA,
    payload,
  };
};

const handleFetchMemberType = (payload) => {
  return {
    type: HANDLE_FETCH_MEMBERTYPE,
    payload,
  };
};
const handleFetchMemberPrice = (payload) => {
  return {
    type: HANDLE_FETCH_MEMBERPRICE,
    payload,
  };
};
const fetchWhitelistDetails = (payload :any) => {
  return {
    type: FETCH_WHITELISTING_DETAILS,
    payload,
  };
};
const fetchwhitelistingdata = (payload :any) => {
  return {
    type: FETCH_WHITELISTING_DATA,
    payload,
  };
};
const setSaveRegistration = (obj:any,callback) => {
  return async (dispatch: any) => {
    dispatch(setCustomerRegister({ key: 'setCustomerRegisterDetails', loading: true, data: null }));
    post(`User/saveregistration`,obj).then((res) => {
      dispatch(setCustomerRegister({ key: 'setCustomerRegisterDetails', loading: true, data: null }));
      if (res) {
        dispatch(setCustomerRegister({ key: 'setCustomerRegisterDetails',loading: false, data: res.data, error: null }));
        callback(res.data)
      } else {
        dispatch(
          setCustomerRegister({key: 'setCustomerRegisterDetails',loading: false, data: null, error: isErrorDispaly(res),}),
        );
      }
    });
  };
};


const getCustomerRegisterDetails = (customerId) => {
  return async (dispatch: any) => {
    dispatch(setIscustomerRegister({ key: 'customerRegisterDetails',loading: true, data: null }));
    get(`User/iscustomerregister/${customerId}`).then((res) => {
      dispatch(setIscustomerRegister({ key: 'customerRegisterDetails',loading: true,   data: null }));
      if (res) {
        dispatch(setIscustomerRegister({ key: 'customerRegisterDetails', loading: false, data: res.data, error: null }));
      } else {
        dispatch(
          setIscustomerRegister({key: 'customerRegisterDetails',loading: false,  data: null, error: isErrorDispaly(res),}),
        );
      }
    });
  };
};

const getMemberTypes = (isConnected,customerId,callback) => {
  return async (dispatch: any) => {
    dispatch(handleFetchMemberType({ key: 'memberType', loading: true, data: [], error: null }));
    let connectedAddress=isConnected==true?customerId:""
    get(`User/GetMemberShipType/${connectedAddress}`).then((res) => {
      if (res) {
        dispatch(handleFetchMemberType({ key: 'memberType', loading: false, data: res.data, error: null }));
        if (callback)
          callback({ loading: false, data: res.data, error: null});
      } else {
        dispatch(
          handleFetchMemberType({
            key: 'memberType',
            loading: false,
            data: [],
            error: isErrorDispaly(res),
          }),
        );
      }
    });
  };
};

const getMemberPrice = (crypto: string, selectedId: any, count: any,callback) => {
  return async (dispatch: any) => {
    dispatch(handleFetchMemberPrice({ key: 'memberPrice', loading: true, data: [] }));
    get(`User/GetMemberShipPrice/${crypto}/${selectedId}/${count}`).then((res) => {
      if (res) {
        dispatch(handleFetchMemberPrice({ key: 'memberPrice', loading: false, data: res.data, error: null }));
       callback(res.data)
      } else {
        dispatch(
          handleFetchMemberPrice({
            key: 'memberPrice',
            loading: false,
            data: [],
            error: isErrorDispaly(res),
          }),
        );
      }
    });
  };
};

const getMetaDataDetails = (count: any, callback: any) => {
  return async (dispatch: any) => {
    dispatch(handleFetchMetaData({ key: 'metaDataDetails', loading: true, data: [] }));
    get(`User/mintfiles/${count}`).then((res) => {
      if (res) {
        dispatch(handleFetchMetaData({ key: 'metaDataDetails', loading: false, data: res.data, error: null }));
        callback(res.data);
      } else {
        dispatch(
          handleFetchMetaData({
            key: 'metaDataDetails',
            loading: false,
            data: [],
            error: isErrorDispaly(res),
          }),
        );
      }
    });
  };
};
const getWhiteListDetails = (id : any,whitListId:any) => {
  return async (dispatch: any) => {
    dispatch(fetchWhitelistDetails({ key: 'fetchWhitelistDetails', loading: true, data: [] }));
    get(`User/membertasks/${id}/${whitListId}`).then((res) => {
      if (res) {
        dispatch(fetchWhitelistDetails({ key: 'fetchWhitelistDetails', loading: false, data: res.data, error: null }));
      } else {
        dispatch(
          fetchWhitelistDetails({
            key: 'fetchWhitelistDetails',
            loading: false,
            data: [],
            error: isErrorDispaly(res),
          }),
        );
      }
    }
    )
  };
};


const getWhiteListData=(id : any)=>{   
    return async (dispatch : any) => {
      try{
      dispatch(fetchwhitelistingdata({ key: 'fetchwhitelistingdata', loading: true, data: [] }));
            const response = await  get(`User/whitelistingK/${id}`)
            if (response) {
                if(response.data){
                  dispatch(fetchwhitelistingdata({ key: 'fetchwhitelistingdata', loading: false, data:  response.data, error: null }));
            }
        }
      } catch (error) {
        dispatch(
                    fetchwhitelistingdata({
                      key: 'fetchwhitelistingdata',
                      loading: false,
                      data: [],
                      error: isErrorDispaly(error),
                    }),
                  );
    }
    
}
}
const isErrorDispaly = (objValue: any) => {
  if (objValue?.status >= 400 && objValue?.status < 500 && objValue?.status != 401) {
    return 'Something went wrong please try again!';
  } else {
    if (objValue?.data && typeof objValue?.data === 'string') {
      return objValue?.data;
    } else if (objValue?.data && objValue?.data.title && typeof objValue?.data.title) {
      return objValue?.data.title;
    } else if (objValue?.originalError && typeof objValue?.originalError.message === 'string') {
      return objValue?.originalError.message;
    } else {
      return 'Something went wrong please try again!';
    }
  }
};

const initialState = {
  user: { id: '', name: '' },
  selectedCollection: null,
  popUpEdit:null,
  metaDataDetails: null,
  memberType: null,
  memberPrice: null,
  customerRegisterDetails:{loading: false, data: null,error:null},
  setCustomerRegisterDetails:{loading: false, data: null,error:null},
  fetchWhitelistDetails:{loading: false, data: null,error:null},
  fetchwhitelistingdata:{loading: false, data: null,error:null},
};

const rootReducer = (state: any, action: any) => {
  if (!state) {
    state = {
      ...initialState,
      ...state,
    };
  }
  switch (action.type) {
    case HANDLE_FETCH_METADATA:
      return {
        ...state,
        [action.payload.key]: {
          data: action.payload.data,
          error: action.payload.error,
          isLoading: action.payload.loading,
        },
      };
      case HANDLE_FETCH_METADATA:
        return {
          ...state,
          [action.payload.key]: {
            data: action.payload.data,
            error: action.payload.error,
            isLoading: action.payload.loading,
          },
        };
    case HANDLE_FETCH_MEMBERTYPE:
      return {
        ...state,
        [action.payload.key]: {
          data: action.payload.data,
          error: action.payload.error,
          isLoading: action.payload.loading,
        },
      };
    case FETCH_WHITELISTING_DETAILS:
      return {
        ...state,
        [action.payload.key]: {
          data: action.payload.data,
          error: action.payload.error,
          isLoading: action.payload.loading,
        },
      };
      case FETCH_WHITELISTING_DATA:
        return {
          ...state,
          [action.payload.key]: {
            data: action.payload.data,
            error: action.payload.error,
            isLoading: action.payload.loading,
          },
        };
      case SET_ISCUSTOMER_REGISTER:
        return {
          ...state,
          [action.payload.key]: {
            data: action.payload.data,
            error: action.payload.error,
            isLoading: action.payload.loading,
          },
        };
        case SET_CUSTOMER_REGISTER:
          return {
            ...state,
            [action.payload.key]: {
              data: action.payload.data,
              error: action.payload.error,
              isLoading: action.payload.loading,
            },
          };
    case SET_USER_ID:
      state = { ...state, user: action.payload };
      return state;
    case SELECTED_COLLECTION:
      state = { ...state, selectedCollection: action.payload };
      return state;
        case SELECTED_EDIT_DATA:
        state = { ...state, popUpEdit: action.payload };
        return state;
    default:
      return state;
  }
};

export default rootReducer;
export {
  setUserID,
  selectedEditData,
  selectedCollection,
  getMetaDataDetails,
  handleFetchMetaData,
  getMemberTypes,
  handleFetchMemberType,
  getCustomerRegisterDetails,
  setIscustomerRegister,
  setCustomerRegister,
  setSaveRegistration,
  getMemberPrice,
  handleFetchMemberPrice,
  getWhiteListDetails,
  fetchWhitelistDetails,
  getWhiteListData,
  fetchwhitelistingdata,

};
