import React, { useEffect } from 'react';
import { selectedCollection, setIscustomerRegister, setUserID } from '../../reducer/rootReducer';
import { getCUstomers } from '../../utils/apiGet';
import { store } from '../../store';
import { useAccount} from 'wagmi';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const KycStatus = () => {
  const { isConnected, address } = useAccount();
  const authInfo = useSelector((state) => state.auth.user);
  const router = useNavigate();
  const handleKyc = () => {
    window.open(process.env.REACT_APP_KYC_APP, '_blank');
  };

  useEffect(()=>{
    getCustomerDetail(address)
  },[])

useEffect(()=>{
  //  if (!authInfo.kycStatus || authInfo?.kycStatus?.toLowerCase() !== 'completed')
  //  { router('/minnapad/kycStatus')
   if(!authInfo.isReferralPage){
    { router('/minnapad/referrals')}
  }
   else{
    router('/minnapad/dashboard')
   }
},[authInfo])

const getCustomerDetail = async (address) => {
  store.dispatch(setIscustomerRegister({ key: 'customerRegisterDetails', data: null }));
  let response = await getCUstomers(`User/CustomerDetails/${address}`);
  if (response) {
    store.dispatch(setUserID(response.data));
    if(isConnected){
      if (response.data.kycStatus == null || response.data.kycStatus?.toLowerCase() !== 'completed') {
        router('/minnapad/kycStatus');
      }
    }
    else  {
      router('/minnapad/dashboard');
    }
    store.dispatch(setUserID(response.data));
    store.dispatch(selectedCollection(response.data.imageUrl));
  }
};

  return (
    <div className="container">
      <div className="proceedKyc card-mt-pt">
        <div className="text-center">
          <h3 className='completekyc-title mb-4'>To proceed with Minting <br/> please <span style={{color:'#0068FF'}}>complete your KYC</span></h3>
          <hr className="my-collection-hr" />
          <button type="button" className="mintnow-btn" onClick={handleKyc}>
            Complete KYC
          </button>
        </div>
        {/* <div className=' staking-bg'></div> */}
      </div>
    </div>
  );
};

export default KycStatus;
