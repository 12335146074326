import axios from 'axios';
import { store } from '../store';
import { getToken } from './api';
const API_END_POINT = process.env.REACT_APP_API_END_POINT;
const API_END_POINT_CUSTOMER=process.env.REACT_APP_API_END_POINT_CUSTOMER
const API_VERSION = 'api/v1/';
export function get(url: string) {
  const token = getToken();
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,{
    headers:{
      Authorization:`${token}`
    }
  });
}

export function getCUstomers(url: string) {
  const token = getToken();
  return axios.get(API_END_POINT_CUSTOMER + `${API_VERSION}${url}`,{
    headers:{
      Authorization:`${token}`
    }
  });
}


export function getReferralsData(url: string) {
  const token = getToken();
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,{
    headers:{
      Authorization:`${token}`
    }
  });
}


export function getBonousData(url: string) {
  const token = getToken();
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,{
    headers:{
      Authorization:`${token}`
    }
  });
}


//https://devkycapi.minnapad.com/api/v1/User/isexistingreferralcode/referralCode

export function isexistingreferralcode(url: string) {
  const token = getToken();
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,{
    headers:{
      Authorization:`${token}`
    }
  });
}


export function getEarnedBonous(url: string) {
  const token = getToken();
  return axios.get(API_END_POINT + `${API_VERSION}${url}`,{
    headers:{
      Authorization:`${token}`
    }
  });
}
