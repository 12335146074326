import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import referral from '../../assets/images/referral.svg';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { useNavigate, useParams } from 'react-router-dom';
import { changeNetwork } from '../../hooks/useChangeChain';
import ToasterMessage from '../../utils/tostermessage';
import popicon from '../../assets/images/connectwallet.svg';
import walletconnect from '../../assets/images/walletconnect.svg';
import Referralcode from './referralcode';
import { post, updateisreferralpage } from '../../utils/api';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';

function Referral() {
    const router = useNavigate();
    const params=useParams()
    const authInfo = useSelector((state: any) => state.auth.user);
    const [isLoading,setisLoading]=useState(false)

   const handleOk=async()=>{
    router(`/minnapad/referralcode`)
  //   let obj={
  //     customerId:authInfo.id,
  //     stauts:"No"
  //   }
  // let response = await updateisreferralpage(`User/updateisreferralpage`,obj) 
  // .then((response) => {
  //     if (response) {
  //         // router('/minnapad/dashboard')
  //         router(`/minnapad/referralcode/${params.id}`)
  //     }
  //   })
  //   .catch((error) => {
     
  //   });
    }

    const handleUpdateReferral= async()=>{
      setisLoading(true)
        let obj={
            customerId:authInfo.id,
            status:"No"
          }
        let response = await updateisreferralpage(`User/updateisreferralpage`,obj) 
        .then((response) => {
            if (response) {
                 router('/minnapad/dashboard')
                 setisLoading(false)
            }
          })
          .catch((error) => {
            setisLoading(false)
          });
    }
  return (
    <>
   <div className='referral-content'>
   <div className='text-center'>
    <img src={referral} ></img>
    <h1 className='detailview-title'>Do You Have A <span className='referral-text'>Referral</span> Code?</h1>
    <hr className='my-collection-hr'></hr>
    <p className='mb-3'>If you have a referral code, you will get 5% </p>
   </div>
   <div className='referral-input position-relative'>
    {/* <p className='referral-head'>Referral Code</p>
<div className='position-absolute name-check'>
    <p>Ramakrishna</p>
    <p className='icon sm check-circle'></p>
</div> */}
  <div className="mt-4 text-center">
    <Button type="button" onClick={handleUpdateReferral} 
    className="border-btn m-0 text-center btn-cancel">
      <span>{isLoading && <Spinner size="sm" className='loader-dark' />}</span>{' '}
    No
    </Button>
    <Button type="button"  onClick={handleOk} className="fill-btn m-0 ms-3 submit-spinner">
    Yes
    </Button>
</div>
   </div>
   </div>
    </>
  );
}


export default Referral;
