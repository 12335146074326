import React from "react";
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import Header from "../components/modules/Header/Header";
import Dashboard from '../components/dashboard.components/dasboard';
import Kyc from '../components/sumsub/index';
import KycStatus from "../components/kycStatus/kycStatus";
import Profile from "../components/profile.component";
import MyCollections from "../components/mycollections"
import Whitelisting from "../components/whitelisting.component";
import TaskCount from "../components/whitelisting.component/taskCount";
import ThankYou from "../components/mintingtask/thankyou"
import Footer from "../components/modules/Footer/Footer";
import { getCUstomers } from "../utils/apiGet";
import { store } from "../store";
import { setToken } from "../reducer/authReducer";
import Pageerror from '../utils/pagenotfound';
import ErrorPage from '../utils/errorpage';
import Referral from "../components/referrals.component/referral"
import ReferralCode from "../components/referrals.component/referralcode"
const Routes = () => {
    const routes = createBrowserRouter([
        {
            path: "/minnapad",
            element: <>
                <Header />
                <Outlet />
                <Footer />
            </>,
            loader: async () => {
                const id = store.getState().auth?.user?.id;
                return getCUstomers(`User/GetAuthorizationToken/${id ? id : ""}`).then(token => {
                    store.dispatch(setToken(token.data));
                    return token
                });
            },
            children: [
                {
                    path: "dashboard",
                    element: <Dashboard />,
                    errorElement :<Pageerror />,
                    // index: true
                },
               
                {
                    path: "home",
                    element: <Dashboard />,
                    errorElement :<Pageerror />,
                },

                {
                    path: "kyc",
                    element: <Kyc />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "kycStatus",
                    element: <KycStatus />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "profile",
                    element: <Profile />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "profile/:show",
                    element: <Profile />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "referrals",
                    element: <Referral />,
                    errorElement :<Pageerror />,
                  },
                  {
                    path: "referralcode",
                    element: <ReferralCode />,
                    errorElement :<Pageerror />,
                  },
                {
                    path: "mycollections",
                    element: <MyCollections />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "whitelisting",
                    element: <Whitelisting />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "whitelisting/taskcount/:id?/:whiteListId",
                    element: <TaskCount />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "thankyou",
                    element: <ThankYou />,
                    errorElement :<Pageerror />,
                },
                {
                    path: "/minnapad", element: <Navigate to="/minnapad/dashboard" />,
                    errorElement :<Pageerror />,
                },
                
            ]

        },
        {
            path: "/", element: <Navigate to="/minnapad/dashboard" />,errorElement :<Pageerror />,
        },
        {
            path: "*",
             element: <React.Suspense fallback={<div className="text-center"></div>}><ErrorPage /></React.Suspense>
        },
        {
            path: "*",
             element: <React.Suspense fallback={<div className="text-center"></div>}><Pageerror /></React.Suspense>
         },

    ]);
    return <>
        <RouterProvider router={routes} />
    </>
}
export default Routes