import React from 'react';
import Content from './layout/content';
import { Provider } from 'react-redux';
import { store } from './store';
function App() {
  return (
   <Provider store={store}>
    <Content/>
   </Provider>
  );
}

export default App;
