import { ethers } from 'ethers';
import Contract from './mint.json';
import WETHContract from './weth.json';
import { prepareWriteContract, waitForTransaction, writeContract } from 'wagmi/actions';
export function useContract() {
  const PRIVATE_KEY = process.env.REACT_APP_OWNER_PRIVATE_KEY;
  async function minMultipleNft(uriArr: any, coinDetails: any, nftPrice: any, address) {
    const private_key: any = PRIVATE_KEY;
    const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ALCHEMY_PROVIDER);
    var aggHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(uriArr[0]));
    for (var i = 1; i < uriArr.length; i++) {
      aggHash = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(`${uriArr[i]};${aggHash}`));
    }
    const nonce = Math.floor(new Date().getTime() / 1000)
    const finalAggHash = ethers.utils.solidityKeccak256(
      ["address", "string", "uint"],
      [address, aggHash, nonce]
    );

    const wallet = new ethers.Wallet(private_key, provider);
    const MSG_HASH = ethers.utils.arrayify(finalAggHash);
    const validSign = await wallet.signMessage(MSG_HASH);
    if (coinDetails == 'Matic') {
      return mintNativeWithWagmi(uriArr, aggHash, { signature: validSign, nonce }, {
        value: ethers.utils.parseUnits(nftPrice.toFixed(8), 18),
        gasLimit: 900000,
        gasPrice: 300000,
      });
    } else {
      await approveEth(ethers.utils.parseUnits(nftPrice.toFixed(8).toString(), 18));
      return mintTokenWithWagmi(uriArr, aggHash, { signature: validSign, nonce }, {
        gasLimit: 900000,
        gasPrice: 300000,
      });
    }
  }

  function parseError(message) {
    let _message = message?.details || message?.cause?.reason || message?.message || message.fault;
    return _message;
  }

  async function mintNativeWithWagmi(args1, args2, args3, args4) {
    const { request } = await prepareWriteContract({
      address: process.env.REACT_APP_MINTING_CONTRACTOR,
      abi: Contract.abi,
      functionName: "safeMintMultiple",
      args: [args1, args2, args3],
      value: args4.value
    });
    return writeContract(request);
  }
  async function mintTokenWithWagmi(args1, args2, args3, args4) {
    const { request } = await prepareWriteContract({
      address: process.env.REACT_APP_MINTING_CONTRACTOR,
      abi: Contract.abi,
      functionName: "safeMintMultipleWithToken",
      args: [args1, args2, args3],
      gasPrice: args4.gasPrice,
      gas: args4.gasLimit,
    });
    return await writeContract(request);
  }

  async function approveEth(value) {
    const { request } = await prepareWriteContract({
      address: WETHContract.contractAddress,
      abi: WETHContract.abi,
      functionName: "approve",
      args: [process.env.REACT_APP_MINTING_CONTRACTOR, value]
    });
    const { hash } = await writeContract(request);
    return await waitForTransaction({ hash: hash });
  }
  return { minMultipleNft, parseError };

}