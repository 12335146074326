const SET_TOKEN = "setToken";
const REMOVE_TOKEN = "removeToken";

const setToken = (payload) => {
    return {
        type: SET_TOKEN,
        payload
    }
}
const removeToken = () => {
    return {
        type: REMOVE_TOKEN,
        payload: null
    }
}
let initialState = {
    token: null
}
const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_TOKEN:
            return { ...state, token: action.payload };
        case REMOVE_TOKEN:
            return { token: null };
        default:
            return state;
    }
}

export { setToken, removeToken };
export default authReducer;