import axios from 'axios';
import { store } from '../store';
const API_END_POINT = process.env.REACT_APP_API_END_POINT;
const API_END_POINT_CUSTOMER = process.env.REACT_APP_API_END_POINT_CUSTOMER
const API_VERSION = 'api/v1/';
const API_SIGNER_END_POINT= process.env.REACT_APP_API_SIGNATURE
export function getToken() {
  const state = store.getState();
  const token = state.auth.user?.token || state.profile.token;
  return token;

}
async function post(url: any, obj: any) {
  return await axios.post(`${API_END_POINT}${API_VERSION}${url}`, obj, {
    headers: {
      Authorization: `${getToken()}`
    }
  });
}


async function postSigner(url:any,obj:any){
  return await axios.post(`${API_SIGNER_END_POINT}${url}`,obj, {
    headers: {
      Authorization: `${getToken()}`
    }
  })
}

async function kycAudit(url: any, obj: any) {
  return await axios.post(`${API_END_POINT_CUSTOMER}${API_VERSION}${url}`, obj, {
    headers: {
      Authorization: `${getToken()}`
    }
  });
}

async function updateisreferralpage(url: any, obj: any) {
  return await axios.put(`${API_END_POINT}${API_VERSION}${url}`, obj, {
    headers: {
      Authorization: `${getToken()}`
    }
  });
}




export { post, kycAudit,postSigner,updateisreferralpage };
